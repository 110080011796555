<!--
 * @Author: jiang
 * @Date: 2021-07-12 10:01:40
 * @Description:
-->
<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="formRules"
    label-position="top"
  >


    <el-row :gutter="20">



      <el-col >
        <el-form-item
          label="所在类目:"
          prop="index_category_id"
        >
          <el-input
            placeholder="请输入内容"
            v-model="data.currentIndexName"
            :disabled="true">
          </el-input>
        </el-form-item>
      </el-col>
   <el-col>
        <el-form-item
          label="基础数据标题"
          prop="title"
        >
          <el-input
            v-model="formData.title"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col
        :span="12"
        v-if="userId == userSuperId || userId==1269"
      >
        <el-form-item
          label="所属部门"
          prop="department_id"
        >
          <select-dept
            v-if="userId === userSuperId  || userId==1269"
            v-model="formData.department_id"
          ></select-dept>
        </el-form-item>
      </el-col>
      <el-col
        :span="12"
        v-if="userId === userSuperId|| userId==1269"
      >
        <el-form-item
          label="排序(数字越大排序越靠前)"
          prop="order"
        >
          <el-input
            v-model="formData.order"
            type="number"
            placeholder="请输入，数字越大排序越靠前"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col v-if="0">
        <el-form-item
          label="内容"
          prop="content"

        >
          <textbus-editor v-model="formData.content"></textbus-editor>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="部门工作基础数据手册电子版:"
          prop="media_keys"
        >
          <c-upload-media v-model="formData.media_keys"></c-upload-media>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <div class="g-center">
        <el-button
          :loading="submitLoading"
          type="primary"
          icon="el-icon-plus"
          @click="onSubmit"
        >保存</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import CUploadMedia from '@/components/upload/file.vue'
import TextbusEditor from '@/components/editor/textbus'
import SelectDept from '@/components/select/cascader/dept.vue'
import { createRecods } from '@/api/manager'

export default {
  props: {
    type: String,
    data: {
      type: Object,
    },
  },
  components: {
    CUploadMedia,
    TextbusEditor,
    SelectDept,
  },
  data() {
    return {
      userSuperId: process.env.VUE_APP_SUPER_USER_ID,
      submitLoading: false,
      formData: {
        title: '',
        department_id: '',
        order: 0,
        content: '',
        media_keys: [],
      },
      formRules: {
        title: { required: true, message: '必须填写', trigger: 'blur' },
        index_category_id:{ required: true, message: '请先选择左侧数据分类！', trigger: 'blur' },
        department_id: { required: true, message: '部门必须填写', trigger: 'blur' },
        media_keys:{ required: true, message: '请上传工作基础数据手册', trigger: 'blur' },
      },
    }
  },
  computed: {
    userId() {
      return this.$store.state.user.userInfo.id + ''
    },
    userDepartment() {
      return this.$store.state.user.userInfo.department.name
    },
  },
  watch: {
    data: {
      handler(val) {
        this.formData = val
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSubmit() {
      // console.log(this.type, this.formData)
      this.$refs.form.validate(valid => {
        if (valid) {

          this.submitLoading = true
          const params = {
            type: this.type,
            user_id: this.$store.state.user.userInfo.id,
            department_id: this.formData.department_id,
            title: this.formData.title,
            content: this.formData.content,
            media_keys: this.formData.media_keys,
            order: this.formData.order ?? 0,
            index_category_id:this.data.index_category_id
          }
          createRecods(params)
            .then(() => {
              this.submitLoading = false
              this.formData.media_keys=[];
              this.$message.success('创建成功')
              this.$emit('success')
            })
            .finally(() => {
              this.formData.media_keys=[];
              this.submitLoading = false
            })
        }
      })
    },
  },
}
</script>
