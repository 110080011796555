<!--
 * @Author: jiang
 * @Date: 2021-06-14 08:48:32
 * @Description: 附件上传
-->
<template>
  <div>
    <el-upload action="false" :accept="acceptList" :file-list="fileList" :http-request="onUploadMedia"
      :before-remove="onRemove" :multiple="true">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">仅支持上传.doc、.docx、xls、ppt文件，且不超过100MB</div>

    </el-upload>
  </div>
</template>

<script>
  import {
    uploadMedia,
    removeMedia
  } from '@/api/common/upload'
  export default {
    props: {
      value: {},
      default: {},
    },
    data() {
      return {
        acceptList: [
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'application/vnd.ms-powerpoint',
          'text/plain',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'application/ofd',
          'application/kswps',
          'application/kset',
          'application/ksdps',
          'application/msword',
          'application/kswps',
          'application/msword',
          'application/kswps',

          'application/wps-office.doc',
          'application/wps-office.docx',
          'text/csv',
        ].join(','),
        mediaList: [],
        fileList: [],
      }
    },
    watch: {
      default: {
        handler(val) {
          if (val) {
            // console.log(val)
            this.fileList = val.map(item => {
              return {
                flag: true,
                key: item.id,
                name: item.original_filename + '.' + item.extension,
                url: item.url,
              }
            })
          }
        },
        immediate: true,
      },
    },
    methods: {
      onRemove(file, fileList) {
        return this.$confirm('确定要移除附件吗？', '提示', {
          type: 'warning'
        }).then(() => {
          if (file.flag === true) {
            // const index = this.default.findIndex(item => item.url === file.url)
            return removeMedia(file.key).then(() => {
              // this.default.splice(index, 1)
              // console.log(index, this.default)
              return true
            })
          } else {
            const index = this.mediaList.findIndex(item => item === file.response)
            this.mediaList.splice(index, 1)
            this.$emit('input', this.mediaList)
            return true
          }
        })
      },
      onUploadMedia(data) {
        // console.log(data)
        uploadMedia(data.file)
          .then(res => {
            this.$message({
              message: '上传成功',
              type: 'success'
            });
            data.onSuccess(res.media_key)
            this.mediaList.push(res.media_key)
            this.$emit('input', this.mediaList)
          })
          .catch(() => {
            this.$message.error('上传失败');
            data.onError()
          })
      },
    },
  }
</script>
